<template>
  <div class="editIntroduction">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">个性签名</div>
      <div class="rightBtn" @click="modifyIntroduc">保存</div>
    </div>
    <van-field
      class="newInput"
      v-model="introduction"
      rows="4"
      autosize
      type="textarea"
      maxlength="150"
      :placeholder="placeholderText"
      show-word-limit
    />
  </div>
</template>

<script>
import { Field, Toast } from "vant";
import { modifyUserInfo } from "@/api/user";
export default {
  components: {
    [Field.name]: Field,
  },
  data() {
    return {
      introduction: "",
      placeholderText: "有趣的介绍能让你的逼格提高N个档次！...",
    };
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 修改简介
    async modifyIntroduc() {
      let ret = await this.$Api(modifyUserInfo, { summary: this.introduction });
      if (ret && ret.code == 200) {
        this.$router.go(-1);
        Toast("编辑成功");
      } else {
        Toast(ret.tip || "编辑失败，请稍后再试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editIntroduction {
  height: 100%;
  color: #999999;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    // border-bottom: 1px solid #2b274a;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      color: #333;
      font-size: 18px;
    }

    .rightBtn {
      width: 34px;
      font-size: 14px;
    }
  }

  .newInput {
    // padding: 0 0 12px 0;
    width: 343px;
    margin: 12px auto 0;
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 15px;
    background: #ebebeb;
    // border-bottom: 1px solid #282544;

    ::placeholder {
      font-size: 12px;
      color: #999999;
    }

    /deep/ .van-field__control {
      font-size: 12px;
      color: #000;
    }
    /deep/ .van-field__word-limit {
      font-size: 12px;
      color: #999999;
    }

    .clearBtn {
      margin-right: 12px;
      width: 22px;
      height: 22px;

      .inputClose {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
